<template>
  <div>
    <pageTitle title="业务进度跟踪" style="background: #fff">
      <template slot="right">
        <el-button
          type="primary"
          size="small"
          @click="$router.back()"
          style="margin-right: 12px"
          >返回列表</el-button
        >
      </template>
    </pageTitle>
    <div class="content">
      <div class="list">
        <div class="status_list">          
          <div class="status-title">
            最新进度
          </div>
          <div class="status-cont" v-if="dataList.length">
            <div v-for="item in dataList" :key="item.id">
              <span class="node-title">{{ item.node_name_zh || item.node_name_en | textFormat}}</span>
              <span class="node-detail"> {{ item.details_name_zh || item.details_name_en | textFormat}} </span> 更新时间：
              <span class="update-time"> {{ item.updated_time | secondFormat("LLLL")}} </span>
            </div>
          </div>
          <div class="nomsg" v-else>最近72小时内无业务状态更新，请耐心等待</div>
        </div>
        <div class="time_limit_list" v-if="time_limit_list.length">
          <div class="limit-title">时限提醒</div>
          <div class="limit-cont" >
            <div v-for="item in time_limit_list" :key="item.id">
              <span class="title">{{ item.node_name_zh || item.node_name_en | textFormat}}</span> 所需资料于 
              <span class="red"> {{ item.submit_day | secondFormat("LL")}} </span> 截止提交，请及时联系客服确认资料是否完整。注意：需提交资料包括：
              <span class="limit-detail"> {{ item.limit_remarks | textFormat}} </span>
            </div>
          </div>
        </div>
        <div class="btns">
          <div>{{job_no}}</div> 
          <div class="viewAll" @click="viewAllFunc">查看全部进度 <i class="el-icon-d-arrow-right"></i></div>
        </div>
      </div>
      <tabs class="tabs" @upEtd="getEtd"></tabs>
      <el-dialog title="查看订单状态" :visible.sync="dialogFormVisible" width="600px">
        <div class="viewbox">
          <div class="view2Item" v-for="(item) in sopList" :key="item.id">
            <div class="yuan" :class="(item.details_status == 2 || item.details_status == 3) ? 'bg7FFF00' : '' "></div>
            <div>
              <div class="status" :class=" (item.details_status == 2 || item.details_status == 3) ? 'color7FFF00' : '' ">
              {{ item.node_name_zh || node_name_en | textFormat }}    
                <span :class="(item.details_status == 2 || item.details_status == 3) ? 'color7FFF00' : 'color888' ">
                {{ item.details_name_zh || details_name_en | textFormat  }}</span>
                <template v-if="item.limit_is_show">
                  <span class="date" v-if="(item.details_status == 2 || item.details_status == 3)">{{ item.updated_time | secondFormat }}</span>
                  <span class="limit" :class="item.is_late ?'color888':'' ">{{ item.submit_day | secondFormat('LL')}}  截止提交资料</span>
                </template>
              </div>
              <div class="remarks" :class=" (item.details_status == 2 || item.details_status == 3) ? 'color7FFF00' : '' " v-if="(item.details_status == 2 || item.details_status == 3)">{{ item.remarks  | textFormat}}</div>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogFormVisible = false">关闭</el-button>
        </div>
      </el-dialog>
      
    </div>
  </div>
</template>

<script>
import pageTitle from "~bac/components/common/pageTitle";
import tabs from "~bac/components/dealManagement/myEntrust/detailsOrdered/tabs";

export default {
  name: "detailsOrdered",
  components: { pageTitle, tabs },
  data() {
    return {
      dataList: [],
      sopList: [],
      time_limit_list:[],
      dialogFormVisible: false,
      job_no:''
    }
  },
  mounted() {

    this.getDataList()
    this.getSeaSopstatus();
  },
  methods: {
    viewAllFunc(){
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.shippingOrder.lookAll",
        content: JSON.stringify({
          id: this.$route.query.id || ''
        }),
      });
      this.dialogFormVisible = true;
    },
    async getDataList() {
      let params = {
        order_id: this.$route.query.id
      }
      let res = await this.$store.dispatch("baseConsole/getSeaNewestSopstatus", params);
      if (res.success) {
        this.dataList = res.status_list;
        this.time_limit_list = res.time_limit_list;
      }
    },
    async getSeaSopstatus() {
      let params = {
        order_id: this.$route.query.id
      }
      let res = await this.$store.dispatch("baseConsole/getSeaSopstatus", params);
      if (res.success) {
        this.sopList = res.data;
      }
    },
    getEtd(data) {
      this.job_no = data;
    },
  }
}
</script>

<style scoped lang="less">
.content {
  border-top: 1px solid #cccccc;
  .list {
    padding: 12px;
    font-size: 16px;
    background: #ffffff;
    position: relative;
    padding-right: 170px;
    .btns{
      position: absolute;
      text-align: right;
      background-color: #ffffff;
      padding: 10px 30px;
      right: 0;
      top: 0;
      font-size: 14px;
      .viewAll{
        color: #20a0ff;
        margin-top: 15px;
        cursor: pointer;
      }
    }
    .status_list{
      display: flex;
      .status-title{
        width: 130px;
        background-color: rgb(56, 148, 255);
        font-size: 14px;
        border-radius: 3px;
        color:white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .nomsg{
        color: #999999;
        font-size: 14px;
        padding: 20px;
      }
      .status-cont{
        margin-left: 30px;
        font-size: 14px;
        div{
          line-height: 30px;
        }
        .node-title{
          font-size: 16px;
          color:#101010;
        }
        .node-detail{
          background-color: rgb(56, 148, 255);
          color: white;
          text-align: center;
          font-size: 12px;
          padding: 2px 4px;
          line-height: 36px;
          margin: 0 15px;
          border-radius: 3px;
        }
        .update-time{
          font-size: 14px;
        }
      }
    }
    
    .time_limit_list{
      border-top: 1px solid #f8f8f8;
      display: flex;
      .limit-title{
        width: 130px;
        background-color: rgb(122, 199, 86);
        font-size: 14px;
        border-radius: 3px;
        color:white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
     .nomsg{
        color: #999999;
        font-size: 14px;
        padding: 10px 20px;
      } 
      .limit-cont{
        margin-left: 30px;
        padding: 5px;
        div{
          line-height: 30px;
          font-size: 14px;
        }
        .title , .red{
          color: red;
          font-size: 16px;
        }
        .red{
          font-size: 14px;
        }
      }
    }
    
  }
 
}
.tabs {
  margin-top: 10px;
  background: #FFFFFF;
  padding: 12px;
}

.marginT10 {
  margin-top: 10px;
}

.marginT38 {
  margin-top: 38px;
}

.colorGreen {
  color: rgb(82, 196, 26)
}

.color999 {
  color: #999999;
}
.colorGreen {
  color: rgb(82, 196, 26)
}
.color999 {
  color: #999999;
}
.status_update_time {
  font-size: 14px;
  color: #cccccc;
  height: 18px;
}

.colorBlue {
  color: #20a0ff;
}

.colorRed {
  color: red;
}

.color999 {
  color: #999999;
}

.point {
  cursor: pointer;
}
.viewbox{
  padding: 0 20px;
}
.view2Item {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px dashed #ccc;
  align-items: center;
}
.yuan {
  width: 13px;
  height: 13px;
  background: #888888;
  border-radius: 50%;
  margin-right: 13px;
}
 .date {
    font-size: 12px;
    margin-left: 20px;
    color: #888888;
  }
  .limit{
    margin-left: 20px;
    font-size: 14px;
    color: red;
  }
.remarks{
  margin-top: 10px;
  font-size: 12px;
}
.bg7FFF00 {
  background: #20a0ff!important;
}
.color7FFF00 {
  color: #20a0ff!important;
}
.color888 {
  color: #888888;
}
</style>